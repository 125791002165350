<template>
  <div id="Page-News-Content">
		Page-News-Content
		{{id}}
  </div>
</template>

<script>
export default {
  name: 'Page-News-Content',
  components: {
    
  },
  data(){
    return {
			id: this.$route.params.id,
			item:{}
    };
  },
  async mounted () {
		var result = await this.request.zd.get("NEWS_"+this.id);
		if(result && result.id){
			this.$set(this,'item',result);
		}
  },
  methods: {
		
  }
}
</script>

<style scoped lang="less">
	
</style>